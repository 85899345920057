import { PageParagraphs } from "../PageParagraphs";
import React, { ReactNode } from "react";

const Card = ({
  children,
  title,
  className,
  icon,
}: {
  children: ReactNode;
  title?: string;
  className?: string;
  icon?: ReactNode;
}) => (
  <div className={className}>
    <PageParagraphs gap="gap-3">
      {icon}
      {title && <span className="text-xl font-bold">{title}</span>}
      <div>{children}</div>
    </PageParagraphs>
  </div>
);

export { Card };
