import { PageContainer } from "../PageContainer";
import { PageSection } from "../PageSection";
import { PageSectionContent } from "../PageSectionContent";
import { SiteButton, SiteButtonType } from "../SiteButton";
import image from "./SEO-2.svg";
import React from "react";
import Fade from "react-reveal/Fade";

const SectionHomepageSelfManaged = () => (
  <PageSection>
    <div className="-translate-y-16 py-40 pb-20">
      <PageContainer>
        <div className="-md-16 mx-0 grid md:grid-cols-2 gap-12 rounded-xl bg-gopurple p-4 md:p-16 drop-shadow-2xl xl:-mx-16">
          <Fade>
            <div className="flex items-center justify-center">
              <img className="w-10/12" src={image} />
            </div>
          </Fade>
          <Fade bottom>
            <PageSectionContent
              className="text-white"
              tag="Self-Managed Solutions"
              heading="Our powerful cloud portal gives you access to everything you
            could ever need."
              content="Our cloud portal allows you to purchase Microsoft 365 services,
            web-hosting, scaleable hosting, SSL certificates and lots more!
            It's simple to use and completely free! You only pay for what you
            use. With some of the lowest prices on the market, the goSystem
            Cloud Portal will become your go-to for cloud services."
            >
              <SiteButton
                type={SiteButtonType.TOPURPLE}
                href="https://cloud.gosystem.co.uk/"
              >
                Discover our cloud portal
              </SiteButton>
            </PageSectionContent>
          </Fade>
        </div>
      </PageContainer>
    </div>
  </PageSection>
);

export { SectionHomepageSelfManaged };
