import { PageContainer } from "../PageContainer";
import { PageParticleSection } from "../PageParticleSection";
import { SiteButton, SiteButtonType } from "../SiteButton";
import { StaticImage } from "gatsby-plugin-image";
import { GoBlink } from "gotyping";
import React from "react";
import Fade from "react-reveal/Fade";

const text = [
  "Cloud Hosting",
  "Digital Media & Broadcasting",
  "Web Hosting",
  "Domain Services",
  "Web Development",
  "Disaster Recovery",
  "Network Security",
  "Global Networking",
  "Web-App Development",
  "On-Site IT Solutions",
  "Cloud Services",
  "Telecommunications",
  "Cloud Networking",
  "Web Security",
  "goSystem",
];

const SectionHomepageJumbotron = () => {
  return (
    <PageParticleSection>
      <PageContainer>
        <div className="grid gap-6 pt-32 md:pt-48 pb-52 md:grid-cols-jumbotron">
          <div className="flex flex-col text-white">
            <Fade top>
              <p className="text-4xl sm:text-5xl lg:text-6xl font-black">
                <GoBlink prefix="We are" messages={text} />
              </p>
            </Fade>
            <Fade bottom>
              <p className="pb-8 text-xl md:text-2xl md:leading-9">
                goSystem is an independent IT Service provider. Our wide-ranging
                and industry-leading solutions empower everyone from sole
                traders to large enterprises with economical and cutting-edge IT
                solutions to perfectly meet your ever-changing needs.
              </p>
            </Fade>
            <Fade bottom>
              <SiteButton type={SiteButtonType.TOWHITE} to="/solutions">
                Learn More
              </SiteButton>
            </Fade>
          </div>
          <Fade right>
            <div className="flex justify-center">
              <StaticImage
                src="./SaaS-4.png"
                alt="Clip-art of workers collaboratively working"
                objectFit="contain"
                className="w-fill max-w-sm md:max-w-none md:w-72 lg:w-120"
                placeholder="none"
              />
            </div>
          </Fade>
        </div>
      </PageContainer>
    </PageParticleSection>
  );
};

export { SectionHomepageJumbotron };
