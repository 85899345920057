import { PageContainer } from "../PageContainer";
import { PageParagraphs } from "../PageParagraphs";
import { PageSection } from "../PageSection";
import {
  SiteIconBasicAnticlockwise,
  SiteIconEcommercePound,
  SiteIconTravelPhone247,
} from "../SiteIcon";
import { SiteLink, SiteLinkType } from "../SiteLink";
import React from "react";
import Fade from "react-reveal/Fade";

const SectionHomepageIntroduction = () => (
  <PageSection>
    <div className="-translate-y-16 py-10 pb-20">
      <div className="max-w-screen">
        <PageContainer>
          <div className="-my-16 mx-0 grid grid-cols-1 gap-12 rounded-xl bg-white p-4 md:p-16 drop-shadow-2xl lg:grid-cols-3 xl:-m-16">
            <Fade delay={0}>
              <PageParagraphs>
                <SiteIconTravelPhone247 className="w-10 fill-gopurple" />
                <span className="text-2xl font-black">24/7 Global Support</span>
                <p className="leading-7">
                  Our rapid support is available whenever you need us. Get
                  expert support and incident response to ensure maximum
                  availability and reliability. During office hours, you can
                  also contact your goSystem rep as a direct point of call who
                  is fully trained to help and answer any questions or issues
                  you have.
                </p>
                <SiteLink
                  className="text-gopurple"
                  type={SiteLinkType.SECONDARY}
                  to="/"
                >
                  Help and Support Portal
                </SiteLink>
              </PageParagraphs>
            </Fade>
            <Fade delay={100}>
              <PageParagraphs>
                <SiteIconEcommercePound className="w-10 fill-transparent stroke-gopurple stroke-2" />
                <span className="text-2xl font-black">Competitive Pricing</span>
                <p className="leading-7">
                  Not only are our solutions industry-leading, but we guarantee
                  you won't find a better price. If you find a better price
                  elsewhere, we will either match it or give you an even better
                  one! We perform monthly pricing audits on all of our services
                  to ensure you get the best price.
                </p>
                <SiteLink
                  className="text-gopurple"
                  type={SiteLinkType.SECONDARY}
                  to="/"
                >
                  Find out more
                </SiteLink>
              </PageParagraphs>
            </Fade>
            <Fade delay={200}>
              <PageParagraphs>
                <SiteIconBasicAnticlockwise className="w-10 fill-transparent stroke-gopurple stroke-2" />
                <span className="text-2xl font-black">Rapid Turnaround</span>
                <p className="leading-7">
                  From procurement to deployment, our rapid turnaround ensures
                  your solution is ready to go as soon as possible, with minimal
                  disruption to existing services. We design our deployments
                  specifically to fit your needs and requirements.
                </p>
                <SiteLink
                  className="text-gopurple"
                  type={SiteLinkType.SECONDARY}
                  to="/"
                >
                  About us
                </SiteLink>
              </PageParagraphs>
            </Fade>
          </div>
        </PageContainer>
      </div>
    </div>
  </PageSection>
);

export { SectionHomepageIntroduction };
