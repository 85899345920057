import { Card } from "../Card";
import { CardContainer } from "../CardContainer";
import { PageContainer } from "../PageContainer";
import { PageSection } from "../PageSection";
import { PageSectionContent } from "../PageSectionContent";
import {
  SiteIconBasicLock,
  SiteIconBasicServer2,
  SiteIconBasicSmartphone,
  SiteIconBasicWebpageImgTxt,
  SiteIconTravelWorldWideWeb,
} from "../SiteIcon";
import React from "react";
import { FaConnectdevelop } from "react-icons/fa";
import Fade from "react-reveal/Fade";

const SectionServices = ({
  className,
  cardClass,
  fillColour = "",
  strokeColour = "",
}: {
  className?: string;
  cardClass?: string;
  fillColour?: string;
  strokeColour?: string;
}) => (
  <PageSection>
    <div className={className}>
      <PageContainer>
        <PageSectionContent
          className="py-24 text-center"
          headingClass="text-5xl"
          tag="What we do"
          tagClass="text-golightgrey"
          heading="Our services"
          content="Our wide-ranging service portfolio is designed to give you the
            right tools to help accelerate your business."
        >
          <div className="text-left">
            <CardContainer>
              {[
                <Card
                  title="Cloud services"
                  className={cardClass}
                  icon={
                    <SiteIconBasicServer2
                      className={`h-11 w-11 fill-transparent stroke-2 ${strokeColour}`}
                    />
                  }
                >
                  <p>
                    Our wide range of cloud services provides everything from
                    managed Microsoft 365, Azure & AWS scalable hosting services
                    to unified communications and cloud-based SIP-telephony.
                  </p>
                </Card>,
                <Card
                  title="Web services"
                  className={cardClass}
                  icon={
                    <SiteIconTravelWorldWideWeb
                      className={`h-11 w-11 fill-white ${fillColour}`}
                    />
                  }
                >
                  goSystem provide manage web services, including DNS services,
                  SSL Management, Domain Management and Domain Registration. We
                  take the hassle out of web management.
                </Card>,
                <Card
                  title="Networking Services"
                  className={cardClass}
                  icon={
                    <FaConnectdevelop
                      className={`-m-1 h-12 w-12 ${fillColour}`}
                    />
                  }
                >
                  <p>
                    We provide managed cloud and on-premise networking from our
                    goAnywhere VPN solutions to managed multi-site networking
                    using E2E connections or SD-WAN.
                  </p>
                </Card>,
                <Card
                  title="Telecom services"
                  className={cardClass}
                  icon={
                    <SiteIconBasicSmartphone
                      className={`h-11 w-11 fill-transparent stroke-2 ${strokeColour}`}
                    />
                  }
                >
                  <p>
                    Ensure your communications are future-proofed with SIP
                    communication and integrated unified communications,
                    allowing you to take office calls from anywhere, anytime.
                  </p>
                </Card>,
                <Card
                  title="Web app and service"
                  className={cardClass}
                  icon={
                    <SiteIconBasicWebpageImgTxt
                      className={`h-11 w-11 fill-transparent stroke-2 ${strokeColour}`}
                    />
                  }
                >
                  <p>
                    Leave a lasting impression with a goSystem website, connect
                    with more customers with a goSystem web app—our responsive
                    and secure websites are proven to drive sales and
                    engagement.
                  </p>
                </Card>,
                <Card
                  title="Disaster Recovery"
                  className={cardClass}
                  icon={
                    <SiteIconBasicLock
                      className={`h-11 w-11 fill-transparent stroke-2 ${strokeColour}`}
                    />
                  }
                >
                  <p>
                    Our secure off-site data backup services guarantee peace of
                    mind. In the unlikely event of data destruction, know you
                    will always have an up-to-date recovery option with goSystem
                    Secure Disaster Recovery.
                  </p>
                </Card>,
              ].map((card, index) => (
                <Fade delay={index * 150} key={index}>
                  {card}
                </Fade>
              ))}
            </CardContainer>
          </div>
        </PageSectionContent>
      </PageContainer>
    </div>
  </PageSection>
);

export { SectionServices };
