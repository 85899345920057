import { PageContainer } from "../PageContainer";
import { PageSection } from "../PageSection";
import { PageSectionContent } from "../PageSectionContent";
import { SiteButton } from "../SiteButton";
import image from "./SEO-3.svg";
import React from "react";
import Fade from "react-reveal/Fade";

const SectionHomepageSimplify = () => (
  <PageSection>
    <PageContainer>
      <div className="grid md:grid-cols-2 py-12">
        <Fade>
          <div className="flex items-center justify-center pb-8 md:pb-0">
            <img className="w-10/12" src={image} />
          </div>
        </Fade>
        <Fade bottom>
          <PageSectionContent
            className="text-goblack"
            tag="Technology made simple"
            heading="Taking the hassle out of technology."
            content="Whether you are a sole-trader startup needing a website and email
            system, a doctor's surgery wanting to improve communications, a
            retail business wanting a better way to manage your devices -
            goSystem connects your needs to tailored technology solutions. We
            combine our industry-leading support with unprecedented
            reliability and security. We unlock new potential for your
            business with technology."
          >
            <SiteButton to="/solutions">Discover our solutions</SiteButton>
          </PageSectionContent>
        </Fade>
      </div>
    </PageContainer>
  </PageSection>
);

export { SectionHomepageSimplify };
