import { PageContainer } from "../PageContainer";
import { PageSection } from "../PageSection";
import { PageSectionContent } from "../PageSectionContent";
import { SiteButton } from "../SiteButton";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Fade from "react-reveal/Fade";

const SectionHomepageConnected = () => (
  <PageSection>
    <PageContainer>
      <div className="grid md:grid-cols-2 py-12">
        <Fade bottom>
          <PageSectionContent
            className="text-goblack"
            tag="Always stay connected"
            heading="Ensuring you are always connected to the services you require."
            content="It is now more important than ever that you and your company stay
            connected. Technology is forever becoming more critical to daily
            operations. Technology services are required to be reliable and
            secure to ensure smooth operation and productivity while
            protecting your business interests. We provide mission-critical
            services that your company can rely on 24/7/365."
          >
            <SiteButton to="/about-us">
              Discover how we deliver our services
            </SiteButton>
          </PageSectionContent>
        </Fade>
        <Fade>
          <div className="flex items-center justify-center pt-8 md:pt-0">
            <StaticImage
              className="w-10/12"
              src="./SaaS-5.png"
              placeholder="none"
              alt="Worker at computer chatting remotely"
            />
          </div>
        </Fade>
      </div>
    </PageContainer>
  </PageSection>
);

export { SectionHomepageConnected };
