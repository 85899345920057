import { PageLayout } from "../components/PageLayout";
import { SectionCallToAction } from "../components/SectionCallToAction";
import { SectionHomepageConnected } from "../components/SectionHomepageConnected";
import { SectionHomepageIntroduction } from "../components/SectionHomepageIntroduction";
import { SectionHomepageJumbotron } from "../components/SectionHomepageJumbotron";
import { SectionHomepageSelfManaged } from "../components/SectionHomepageSelfManaged";
import { SectionHomepageSimplify } from "../components/SectionHomepageSimplify";
import { SectionPartners } from "../components/SectionPartners";
import { SectionServices } from "../components/SectionServices";
import { SectionTestimonials } from "../components/SectionTestimonials";
import React from "react";

const IndexPage = () => {
  return (
    <PageLayout transparentHeader>
      <SectionHomepageJumbotron />
      <SectionHomepageIntroduction />
      <SectionHomepageSimplify />
      <SectionHomepageConnected />
      <SectionHomepageSelfManaged />
      <SectionServices
        className="-mt-80 bg-godarkpurple pt-48 text-white"
        cardClass="border-2 rounded p-6 border-gopurple bg-godarkpurple2 hover:bg-gopurple transition-colors h-full"
        fillColour="fill-white"
        strokeColour="stroke-white"
      />
      <SectionPartners />
      <SectionTestimonials />
      <SectionCallToAction>
        Empower your business with technology
      </SectionCallToAction>
    </PageLayout>
  );
};

export default IndexPage;
