import { Card } from "../Card";
import { CardContainer } from "../CardContainer";
import { PageContainer } from "../PageContainer";
import { PageSection } from "../PageSection";
import { PageSectionContent } from "../PageSectionContent";
import React from "react";
import Fade from "react-reveal/Fade";

const SectionTestimonials = () => {
  const cardClass = "text-center bg-white rounded-xl drop-shadow p-6";

  return (
    <PageSection>
      <div className="overflow-x-hidden bg-go-secondary-background">
        <PageContainer>
          <PageSectionContent
            className="py-12 text-center"
            tag="Testimonials"
            headingClass="text-5xl text-goblack"
            heading="What clients say about us"
            content="Take a look at what some of our clients say about the services we provide."
          >
            <CardContainer>
              <Fade left>
                <Card className={cardClass}>
                  <p>
                    “Our data is securely stored with goSystem in the event of a
                    disaster. We know that if the worst does happen, not only
                    will goSystem provide us with our data, but even immediately
                    and securely make the data available to us until we are
                    ready."
                  </p>
                </Card>
              </Fade>
              <Fade bottom>
                <Card className={cardClass}>
                  <p>
                    "goSystem have provided us with a beautiful website that has
                    driven sales higher than we have ever seen before. And their
                    analytics platform show us exactly the data we need about
                    our clients visiting patterns"
                  </p>
                </Card>
              </Fade>
              <Fade right>
                <Card className={cardClass}>
                  <p>
                    “With goSystem, cloud services have become effortless. Their
                    managed Microsoft 365 platform enables us to scale from 10
                    accounts to 200 exactly when we need. They are upfront about
                    cost and are always there if we need support"
                  </p>
                </Card>
              </Fade>
            </CardContainer>
          </PageSectionContent>
        </PageContainer>
      </div>
    </PageSection>
  );
};

export { SectionTestimonials };
